import React from "react"
import { graphql } from "gatsby"
import Link from '../utilities/link'
import CommonFooter from "../components/footer/footer"
import SiteNavigation from "../components/header/siteNavigation"
import Img from "gatsby-image"
import Seo from "../components/seo/seo"
import { useTranslation } from "react-i18next"
import { usePageContext } from "../pageContext"

const Blog = ({ data, location }) => {
  const { edges } = data.allMarkdownRemark
  const { t } = useTranslation()
  const { lang } = usePageContext();

  return (
    <>
      <Seo
        title="Storie e Articoli sulla Produzione Industriale Conto Terzi"
        description="Idee, approfondimenti e storie ispiratrici dedicate alle produzione e alla manifattura industriale conto terzi"
        seoImage={data.plant_produttivo.childImageSharp.resize.src}
        lang="it"
        pathname={location.pathname}
      />
      <SiteNavigation />

      <section className="hero is-gradient is-large is-relative">
        <Img
          fluid={data.plant_produttivo.childImageSharp.fluid}
          style={{
            position: "absolute",
            overflow: "hidden",
          }}
          imgStyle={{
            width: "100%",
            height: "100%",
            opacity: "0.1",
          }}
          className="image-wrapper-height-auto"
          alt="Servizi di produzione, manifattura e assemblaggio industriale conto terzi"
        />
        <div className="hero-body">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column is-4 is-offset-1">
                <h1 className="title is-1 is-white">
                  Storie e Articoli sulla Produzione Industriale
                </h1>
              </div>
              <div className="column is-4">
                <h2 className="title is-5">
                  Benvenuti nel nostro diario online, dove condividiamo idee,
                  approfondimenti e storie ispiratrici dedicate alle produzione
                  industriale
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="section">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="columns is-multiline">
              {edges
                .filter(({ node }) => {
                  return node.frontmatter.lang === lang
                })
                .map(({ node }) => {
                  const {
                    title,
                    tempolettura,
                    translatedPath,
                  } = node.frontmatter
                  return (
                    <div className="column is-6">
                      <div className="section">
                        <div
                          style={{
                            paddingBottom: "25px",
                            borderBottom: "3px solid #2196f3",
                            marginBottom: "25px",
                          }}
                        >
                          <span
                            className="tag is-small is-info"
                            // style={{ textTransform: "capitalize" }}
                          >
                            Tempo di Lettura: {tempolettura}
                          </span>
                        </div>
                        <Link to={`/${translatedPath}`}>
                          <p className="title is-3">{title}</p>
                        </Link>
                        <p style={{ paddingTop: "20px" }}>{node.excerpt}</p>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>

      <CommonFooter />
    </>
  )
}

export default Blog

export const blogHomeQuery = graphql`
  query blog {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { category: { eq: "articolo" } } }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            lang
            title
            translatedPath
            tempolettura
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
    plant_produttivo: file(relativePath: { eq: "Plant_Produttivo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
  }
`
